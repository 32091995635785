<template>
    <div class="module">
        <van-nav-bar title="预案管理" left-arrow  @click-left="$router.go(-1)" />
        <div class="record-content">
            <div class="record-block-info" v-for="(item,index) in data" :key="index">
                <div class="flex-space">
                    <div><span>预案名称：</span>{{item.name}}</div>
                </div>
                <div>
                    <span>预案状态：</span>
                    <van-tag size="medium" type="primary" v-if="item.status">正常</van-tag>
                    <van-tag size="medium" type="danger" v-else>停用</van-tag>
                </div>
                <div>
                    <span>预案级别：</span>{{item.level === 1 ? '正常运营模式' : item.level === 2 ? '较严重事件' : '一般事件'}}
                </div>
                <div class="flex-space">
                    <div><span>创建时间：</span>{{item.time}}</div>
                    <div>
                        <van-button type="info" size="mini" @click="showDetail(item)">查看</van-button>
                        <van-button type="primary" v-if="!item.status" size="mini" @click="exec(item)">执行预案</van-button>
                        <van-button type="warning" v-else size="mini" @click="exec(item)">停止预案</van-button>
                    </div>
                </div>
            </div>
        </div>
        <van-dialog v-model="show" title="预案详情" width="90%" confirm-button-color="#1677FF" confirm-button-text="关闭">
            <van-steps direction="vertical" :active="8" active-color="#1677ff">
                <van-step v-for="(item,index) in list" :key="index">
                    <div class="title">
                        {{item.name}}
                    </div>
                    <div class="steps-descrition-0">
                        <div class="steps-no">第{{index+1}}步</div>
                        <div class="title">{{item.title}}</div>
                        <div class="content">{{item.content}}</div>
                    </div>
                </van-step>
            </van-steps>
        </van-dialog>
        <van-overlay :show="overlayShow">
            <div class="wrapper-loading">
               <van-loading color="#1989fa" />
            </div>
        </van-overlay>
    </div>
</template>

<script>
export default {
    data(){
        return{
            data:[
                { 
                    name: '行人预案', status: 0, level: 1, time: '2021-11-01 12:30',
                    detail:[
                        {title:'开启语音播报',content:'广播-隧道内有故障车，请降低车速行驶',dev:[]},
                        {title:'相关人员赶赴现场处理',content:'',dev:[]}
                    ]
                },
                { 
                    name: '车道指示器全部通行', status: 0, level: 1, time: '2021-11-01 08:30',
                    detail:[
                        {title:'车检器全部通行',content:'车道标志-通行',dev:[]},
                        {title:'相关人员赶赴现场处理',content:'',dev:[]}
                    ]
                },
                { 
                    name: '车道指示器全部禁止', status: 0, level: 1, time: '2021-11-01 11:30',
                    detail:[
                        {title:'车检器全部禁止',content:'车道标志-禁止',dev:[]},
                        {title:'相关人员赶赴现场处理',content:'',dev:[]}
                    ]
                },
                { 
                    name: '火灾预案', status: 1, level: 2, time: '2021-11-01 09:30',
                    detail:[
                        {title:'情报板更新',content:'情报板内容-前方事故/横洞绕行',dev:['信息显示屏-出京','信息显示屏-进京']},
                        {title:'车道指示器调整',content:'车道标志-禁止',dev:['出京1组左','出京1组右','出京2组左']},
                        {title:'交通信号灯',content:'信号灯-黄灯',dev:['入京入口信号灯','出京入口信号灯']},
                        {title:'开启广播',content:'广播-隧道内有故障车，请降低车速行驶',dev:['进京B19','进京B18','进京B17']},
                        {title:'相关人员赶赴现场处理',content:'',dev:[]}
                    ]
                },
                { 
                    name: '智能交通预案', status: 0, level: 1, time: '2021-11-01 12:30',
                    detail:[
                        {title:'车检器全部禁止',content:'车道标志-禁止',dev:[]},
                        {title:'相关人员赶赴现场处理',content:'',dev:[]}
                    ]
                },
                { 
                    name: '出京停车预案', status: 0, level: 1, time: '2021-11-01 02:20', 
                    detail:[
                        {title:'信号灯设置成黄色',content:'信号灯-黄灯',dev:['信息显示屏-出京','信息显示屏-进京']},
                        {title:'情报板修改显示',content:'情报板内容-右侧有事故，请靠左行驶',dev:['出京1组左','出京1组右','出京2组左']},
                        {title:'车道指示器设禁止',content:'车道标志-禁止',dev:['入京入口信号灯','出京入口信号灯']},
                        {title:'照明设置最亮',content:'基本照明-手动',dev:['进京B19','进京B18','进京B17']},
                        {title:'开启广播',content:'广播-隧道内右侧有故障车，请降低车速靠左侧行驶',dev:['进京B19','进京B18','进京B17']},
                        {title:'相关人员赶赴现场处理',content:'',dev:[]}
                    ]
                },
                { 
                    name: '进京停车带预案', status: 0, level: 1, time: '2021-11-01 12:30',
                    detail:[
                        {title:'开启广播提醒',content:'广播-隧道内右侧有故障车，请降低车速靠左侧行驶',dev:[]},
                        {title:'相关人员赶赴现场处理',content:'',dev:[]}
                    ]
                },
                { 
                    name: '出京停车带预案', status: 0, level: 1, time: '2021-11-02 12:00',
                    detail:[
                        {title:'开启广播提醒',content:'广播-隧道内右侧有故障车，请降低车速靠左侧行驶',dev:[]},
                        {title:'相关人员赶赴现场处理',content:'',dev:[]}
                    ]
                },
                { 
                    name: '正常停车带管控', status: 1, level: 1, time: '2021-11-02 12:10',
                    detail:[
                        {title:'关闭所有广播',content:'广播-隧道内有故障车，请降低车速行驶',dev:[]},
                        {title:'相关人员赶赴现场处理',content:'',dev:[]}
                    ]
                },
                { 
                    name: '正常管控', status: 0, level: 1, time: '2021-11-02 14:30',
                    detail:[
                        {title:'信号灯设置绿色',content:'信号灯-绿灯',dev:['信息显示屏-出京','信息显示屏-进京']},
                        {title:'车道标志设置通行',content:'车道标志-通行',dev:['入京入口信号灯','出京入口信号灯']},
                        {title:'关闭所有广播',content:'广播-隧道内有故障车，请降低车速行驶',dev:['进京B19','进京B18','进京B17']},
                        {title:'相关人员赶赴现场处理',content:'',dev:[]}
                    ]
                }
            ],
            list:[],
            show:false,
            overlayShow:false
        }
    },
    methods:{
        showDetail(item){
            this.list = item.detail;
            this.show = true;
        },
        exec(item){
            this.$dialog.confirm({
                message:!item.status ? '此操作将执行该预案, 是否继续?' : '此操作将停用该预案, 是否继续?',
            })
            .then(() => {
                this.overlayShow = true;
                this.$toast({
                    message: '操作成功',
                    position: 'bottom',
                });
                setTimeout(()=>{
                    this.overlayShow = false;
                    this.data = this.data.map(v =>
                        ({
                            ...v,
                            status: v.name === item.name ? !v.status : v.status
                        })
                    )
                },1000)
            })
            .catch(() => {})
    }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/tunnel/module.scss';
</style>